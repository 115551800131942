import React from "react";
import { memo } from "react";
import dayjs, { Dayjs } from "dayjs";
import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import { SelectedCellsPopup } from "./ScheduleCell/SelectedCellsPopup";
import { useAppSelector } from "shared/libs/redux";
import { IUsersState } from "entities/user/model";
import { ScheduleRowDialog } from "./ScheduleRow/ScheduleRowDialog";
import { IScheduleUser, ITableHeader } from "entities/schedule/model/schedule";
import Button from "@mui/material/Button";

interface ScheduleRowProps {
  dates: Array<ITableHeader>;
  user: IScheduleUser | null;
  endDate: Dayjs | null;
  startDate: Dayjs | null;
}

export const ScheduleRowView = memo(
  ({ dates, user, startDate, endDate }: ScheduleRowProps) => {
    const [selectedCell, setSelectedCell] = React.useState<
      string | undefined
    >();
    const [isPopupVisible, setIsPopupVisible] = React.useState(false);
    const [buttonDisabled, setButtonDisabled] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [buttonText, setButtonText] = React.useState(
      "Выберите паттерн и время"
    );
    const [timeline, setTimeline] = React.useState("");
    const [currentPattern, setcurrentPattern] = React.useState<number[]>([
      2, 2,
    ]);
    const [startTime, setStartTime] = React.useState<Dayjs | null>(null);
    const [endTime, setEndTime] = React.useState<Dayjs | null>(null);
    const currentUser = useAppSelector((state) => state.user);

    const [open, setOpen] = React.useState(false);

    const [userModal, setUserModal] = React.useState<
      IUsersState | null | undefined
    >(null);

    const handleCellClick = (
      cellKey: string,
      event: React.MouseEvent<HTMLElement>
    ) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setSelectedCell(cellKey);
      setIsPopupVisible(true);
      setAnchorEl(event.currentTarget);
      setStartTime(dayjs(cellKey?.slice(37, 47)).hour(3).minute(0).utc());
      setEndTime(dayjs(cellKey?.slice(37, 47)).hour(3).minute(0).utc());
    };

    const handlePopupClose = () => {
      setIsPopupVisible(false);
    };

    React.useEffect(() => {
      switch (timeline) {
        case "2/2":
          setcurrentPattern([2, 2]);
          break;
        case "5/2":
          setcurrentPattern([5, 2]);
          break;
        case "7/0":
          setcurrentPattern([7, 0]);
          break;
        case "4/3":
          setcurrentPattern([4, 3]);
          break;
        case "2/2/3":
          setcurrentPattern([2, 2, 3, 2, 2, 3]);
          break;
        default:
      }
    }, [timeline]);

    function generateDaysUntilEndOfMonth(
      startDate: dayjs.Dayjs,
      pattern: number[]
    ) {
      const result = [];
      let currentDate = startDate;
      let isSkip = false;

      while (currentDate?.month() === startDate?.month()) {
        for (const step of pattern) {
          if (isSkip) {
            currentDate = dayjs(currentDate).add(step, "day");
          } else {
            for (let i = 0; i < step; i++) {
              if (currentDate?.month() === startDate?.month()) {
                result.push(dayjs(currentDate).format("DD.MM.YYYY"));
              }
              currentDate = dayjs(currentDate).add(1, "day");
            }
          }
          isSkip = !isSkip;
        }
      }

      return result;
    }

    let generatedDates = [""];
    if (timeline && selectedCell) {
      const cellDate = selectedCell?.slice(-10);
      const formattedDate = dayjs(cellDate);

      generatedDates = generateDaysUntilEndOfMonth(
        dayjs(formattedDate),
        currentPattern
      );
    }

    const generatedDateArray = generatedDates;
    const handleClickOpen = (user: IUsersState | undefined) => {
      setUserModal(user);
      setOpen(true);
    };

    return (
      <>
        <TableBody>
          <TableRow
            sx={{
              backgroundColor:
                selectedCell?.slice(0, 36) === `${user?.user.userId}`
                  ? (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(255, 255, 255, 0.08)"
                        : "rgba(0, 0, 0, 0.04)"
                  : "",
            }}
            hover={true}
            key={user?.user.userId}
          >
            <TableCell
              sx={{
                textAlign: "center",
                position: "sticky",
                left: 0,
                zIndex: 10,
                bgcolor: "background.default",
                height: 34,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                align="left"
                width={230}
                noWrap={true}
                variant="body2"
              >
                {user?.user.fullName}
              </Typography>

              {user?.user.userId === currentUser.userId ||
              ["SUPERVISOR", "TEAMLEAD"].includes(currentUser.role) ? (
                <Tooltip followCursor title="Открыть таймтрек">
                  <IconButton
                    onClick={() => handleClickOpen(user?.user)}
                    size="small"
                  >
                    <OpenInNewIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>

            {dates.map((dateObj) => {
              const cellKey = `${user?.user.userId}-${dateObj.date}`;

              const isDateInGeneratedDates = generatedDateArray.includes(
                dayjs(dateObj.date).format("DD.MM.YYYY")
              );

              const timelineItem = user?.timeline.find(
                (item: { date: string }) =>
                  dayjs(item.date).format("DD.MM.YYYY") ===
                  dayjs(dateObj.date).format("DD.MM.YYYY")
              );

              const isDateAfterSelectedCell = selectedCell
                ? dayjs(dateObj.date).isAfter(
                    dayjs(selectedCell.slice(-10)),
                    "day"
                  )
                : false;

              if (timelineItem && timelineItem.ranges.length > 0) {
                const matchingRanges = timelineItem.ranges;

                return (
                  <TableCell
                    sx={{
                      minWidth: 99,
                      maxWidth: 99,
                      bgcolor: (theme) =>
                        dayjs(dateObj.date).isSame(dayjs(), "day")
                          ? matchingRanges
                              .map((item) => item.isConfirmed)
                              .includes(true)
                            ? "RGB(136, 225, 129,0.4)"
                            : theme.palette.mode === "dark"
                            ? "rgb(144, 202, 249, 0.1)"
                            : "rgba(237, 207, 252, 0.2)"
                          : matchingRanges
                              .map((item) => item.isConfirmed)
                              .includes(true)
                          ? "RGB(136, 225, 129,0.4)"
                          : "",

                      opacity:
                        startDate &&
                        dayjs(dateObj.date)
                          .subtract(-1, "day")
                          .isBefore(dayjs())
                          ? 0.5
                          : 1,
                      outline:
                        selectedCell === cellKey
                          ? "rgb(25, 118, 210) solid 1px"
                          : "",
                    }}
                    key={cellKey}
                    onClick={
                      ["SUPERVISOR", "TEAMLEAD"].includes(currentUser.role)
                        ? (event) => handleCellClick(cellKey, event)
                        : undefined
                    }
                  >
                    <Badge
                      sx={{
                        ".MuiBadge-badge": {
                          transform: "scale(1) translate(21%, 27%)",
                          height: "13px",
                          fontSize: "11px",
                          minWidth: "13px",
                          borderRadius: "12px",
                          width: "13px",
                          lineHeight: 1,
                          pl: "7px",
                        },
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        matchingRanges.length > 1 ? matchingRanges.length : null
                      }
                      color="warning"
                    >
                      <Tooltip
                        followCursor
                        title={
                          matchingRanges.length > 1
                            ? matchingRanges.map((range) => (
                                <Box key={cellKey} sx={{ pb: 1 }}>
                                  <Typography variant="body1">
                                    {dayjs(range.startDate)
                                      .utc()
                                      .format("HH:mm")}
                                    {"—"}
                                    {dayjs(range.endDate).utc().format("HH:mm")}
                                  </Typography>
                                  <Typography variant="body2">
                                    Свой слот: {range.isNative ? "Нет" : "Да"}
                                  </Typography>
                                  <Typography variant="body2">
                                    Запланировано:{" "}
                                    {range.isScheduled ? "Да" : "Нет"}
                                  </Typography>
                                </Box>
                              ))
                            : null
                        }
                      >
                        {isDateInGeneratedDates ? (
                          <Chip
                            color="info"
                            size="small"
                            key={cellKey}
                            label={
                              startTime && endTime
                                ? `${startTime.format(
                                    "HH:mm"
                                  )}—${endTime.format("HH:mm")}`
                                : "Время"
                            }
                            variant="outlined"
                            sx={{
                              ".MuiChip-label ": {
                                p: "0",
                              },
                              borderRadius: "6px",

                              width: "90px",

                              ".MuiChip-icon": {
                                m: "0px",
                                p: 0,
                              },
                            }}
                          />
                        ) : (
                          <Chip
                            size="small"
                            key={cellKey}
                            label={
                              isDateAfterSelectedCell &&
                              timeline &&
                              matchingRanges
                                .map((item) => item.isConfirmed)
                                .includes(false) ? (
                                <DeleteOutlineOutlined
                                  sx={{ mt: 0.6 }}
                                  fontSize="small"
                                ></DeleteOutlineOutlined>
                              ) : (
                                `${dayjs(matchingRanges[0].startDate)
                                  .utc()
                                  .format("HH:mm")}—${dayjs(
                                  matchingRanges[0].endDate
                                )
                                  .utc()
                                  .format("HH:mm")}`
                              )
                            }
                            sx={{
                              ".MuiChip-label ": {
                                p: "0",
                              },
                              borderRadius: "6px",
                              bgcolor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? matchingRanges
                                      .map((item) => item.isNative)
                                      .includes(false)
                                    ? "#466279"
                                    : ""
                                  : matchingRanges
                                      .map((item) => item.isNative)
                                      .includes(false)
                                  ? "#d7c8e8"
                                  : "",

                              color: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "white"
                                  : "black",

                              width: "90px",

                              ".MuiChip-icon": {
                                m: "0px",
                              },
                              border: (theme) =>
                                theme.palette.mode === "dark"
                                  ? matchingRanges
                                      .map((item) => item.isScheduled)
                                      .includes(false)
                                    ? "2px solid #ffa726 "
                                    : ""
                                  : matchingRanges
                                      .map((item) => item.isScheduled)
                                      .includes(false)
                                  ? "2px solid #ed6c02"
                                  : "",
                            }}
                          />
                        )}
                      </Tooltip>
                    </Badge>
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    onClick={
                      ["SUPERVISOR", "TEAMLEAD"].includes(currentUser.role)
                        ? (event) => handleCellClick(cellKey, event)
                        : undefined
                    }
                    sx={{
                      minWidth: 99,
                      maxWidth: 99,
                      height: 34,
                      bgcolor: (theme) =>
                        dayjs(dateObj.date).isSame(dayjs(), "day")
                          ? theme.palette.mode === "dark"
                            ? "rgb(144, 202, 249, 0.1)"
                            : "rgba(237, 207, 252, 0.2)"
                          : "",
                      outline:
                        selectedCell === cellKey
                          ? "rgb(25, 118, 210) solid 1px"
                          : "",
                    }}
                    key={cellKey}
                  >
                    {isDateInGeneratedDates ? (
                      <Chip
                        size="small"
                        color="info"
                        key={cellKey}
                        label={
                          startTime && endTime
                            ? `${startTime.format("HH:mm")}—${endTime.format(
                                "HH:mm"
                              )}`
                            : "Время"
                        }
                        variant="outlined"
                        sx={{
                          ".MuiChip-label ": {
                            p: "0",
                          },
                          borderRadius: "6px",
                          width: "90px",
                          ".MuiChip-icon": {
                            m: "0px",
                            p: 0,
                          },
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                );
              }
            })}
            {isPopupVisible && selectedCell && (
              <SelectedCellsPopup
                timeline={timeline}
                setTimeline={setTimeline}
                selectedCell={selectedCell}
                onClose={handlePopupClose}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setSelectedCell={setSelectedCell}
                buttonText={buttonText}
                setButtonText={setButtonText}
                buttonDisabled={buttonDisabled}
                setButtonDisabled={setButtonDisabled}
                startTime={startTime}
                setStartTime={setStartTime}
                endTime={endTime}
                setEndTime={setEndTime}
              />
            )}
          </TableRow>
        </TableBody>
        {open && (
          <ScheduleRowDialog
            user={user}
            startDate={startDate}
            endDate={endDate}
            open={open}
            userModal={userModal}
            setOpen={setOpen}
          />
        )}
      </>
    );
  }
);

export default ScheduleRowView;
