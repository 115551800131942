import React from "react";
import { Dayjs } from "dayjs";
import Dialog from "@mui/material/Dialog";
import { Table } from "shared/ui";
import Typography from "@mui/material/Typography";
import { recordsApi } from "entities/record/api/recordService";
import { IUsersState } from "entities/user/model/user";
import { IScheduleUser } from "entities/schedule/model/schedule";

interface ScheduleRowDialogProps {
  user: IScheduleUser | null;
  endDate: Dayjs | null;
  startDate: Dayjs | null;
  open: boolean;
  userModal: IUsersState | null | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ScheduleRowDialog = ({
  startDate,
  endDate,
  user,
  open,
  userModal,
  setOpen,
}: ScheduleRowDialogProps) => {
  const userRecord = recordsApi.useGetRecordsByUserQuery({
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.add(1, "day").format("YYYY-MM-DD") : "",
    userId: user?.user.userId ? user.user.userId : "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      sx={{
        "& .MuiDataGrid-virtualScroller": {
          overflowY: "auto !important",
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
      maxWidth="xl"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="user-dialog-title"
      aria-describedby="user-dialog-description"
    >
      <Typography bgcolor={"background.paper"} textAlign="center" variant="h6">
        {userModal?.fullName || ""}
      </Typography>
      <Table
        loading={userRecord.isFetching}
        user={userModal}
        data={userRecord.data || []}
      />
    </Dialog>
  );
};
