import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link/Link";
import { useAppDispatch } from "shared/libs/redux";
import { loginThunk } from "entities/user/model";
import { useGetRequestPasswordMutation } from "entities/user/api/userService";
import Snackbar from "@mui/material/Snackbar";

const Auth = () => {
  const [open, setOpen] = useState(false);
  const [isPasswordChange, setIsPasswordChange] = useState<boolean>(false);
  const [requestPasswordChange, result] = useGetRequestPasswordMutation();
  const dispatch = useAppDispatch();
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    isPasswordChange
      ? requestPasswordChange(`${data.get("email")}`)
      : dispatch(loginThunk(data)).then(
          (data) =>
            (data.hasOwnProperty("error") ||
              data.payload.hasOwnProperty("errorCode")) &&
            setOpen(true),
        );
  }
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <SvgIcon
          inheritViewBox
          sx={{
            display: { xs: "none", md: "flex" },
            width: 300,
            height: 40,
            mb: 4,
          }}
        >
          <path
            d="M67.9824 29.138H75.8244V7.96641H86.187V0.693359H67.9824V29.138Z"
            fill="#8654CC"
          />
          <path
            d="M51.7683 22.797C47.9357 22.797 45.5466 20.3227 45.3286 16.2954H64.7715C64.7715 16.2954 64.8928 14.8325 64.8928 13.3283C64.8928 4.79495 59.814 0 51.8495 0C43.4374 0 37.5859 6.13527 37.5859 14.8725C37.5859 23.7309 43.3561 29.8262 51.7683 29.8262C59.5702 29.8262 64.8115 25.3112 64.974 18.6922H57.1773C56.7257 21.2941 54.8153 22.797 51.7683 22.797ZM51.8495 6.58419C54.9933 6.58419 56.8664 7.93999 57.3849 10.8103H45.7465C46.6379 8.12317 48.8348 6.58419 51.8495 6.58419Z"
            fill="#8654CC"
          />
          <path
            d="M264.943 0.693359V7.96641H273.07V29.138H280.914V7.96641H289.041V0.693359H264.943Z"
            fill="#8654CC"
          />
          <path
            d="M23.8122 0.693359L18.4161 18.12L13.1658 0.693359H4.51115L0 29.138H7.19307L9.93562 11.443L15.2388 29.138H21.4154L26.9056 11.1256L29.5424 29.138H36.8568L32.3456 0.693359H23.8122Z"
            fill="#8654CC"
          />
          <path
            d="M100.572 0.000976562C92.2016 0.000976562 87.4466 4.10449 87.6504 11.095H95.7775C95.8175 8.12801 97.3255 6.70901 100.572 6.70901C102.888 6.70901 104.351 7.68425 104.351 9.14712C104.351 10.0824 103.986 10.4474 102.563 10.8538L96.0213 12.6417C89.7635 14.3484 86.959 16.9491 86.959 21.2977C86.959 26.3764 90.6574 29.7898 96.1025 29.7898C100.329 29.7898 103.092 28.0831 104.473 24.8736V29.1396H111.99V10.241C111.99 3.37305 107.643 0.000976562 100.572 0.000976562ZM97.7679 23.203C95.7775 23.203 94.8009 22.2278 94.8009 20.8062C94.8009 19.4646 95.4911 18.6132 97.6389 18.0017L104.424 16.0938V16.6201C104.432 20.2773 101.554 23.203 97.7679 23.203Z"
            fill="#8654CC"
          />
          <path
            d="M236.7 0.693359H228.045L221.947 11.2572H218.82V0.693359H210.979V29.138H218.82V18.2478H221.901L228.452 29.138H237.31L228.657 14.6306L236.7 0.693359Z"
            fill="#8654CC"
          />
          <path
            d="M166.647 0.000976562C158.276 0.000976562 153.521 4.10448 153.725 11.095H161.852C161.892 8.12801 163.4 6.70901 166.647 6.70901C168.969 6.70901 170.425 7.68425 170.425 9.14712C170.425 10.0824 170.06 10.4474 168.637 10.8538L162.095 12.6417C155.838 14.3484 153.033 16.9491 153.033 21.2977C153.033 26.3764 156.732 29.7898 162.177 29.7898C166.403 29.7898 169.166 28.1244 170.548 24.8736V29.1396H178.064V10.241C178.064 3.37305 173.717 0.000976562 166.647 0.000976562ZM163.809 23.203C161.858 23.203 160.883 22.2278 160.883 20.8062C160.883 19.4646 161.573 18.6519 163.727 18.043L170.513 16.1351V16.6201C170.506 20.4824 167.622 23.203 163.802 23.203H163.809Z"
            fill="#8654CC"
          />
          <path
            d="M197.243 0.000976562C193.489 0.000976562 190.676 1.91019 189.564 4.95718V0.691134H181.721V36.8977H189.564V24.9574C190.725 27.976 193.523 29.7859 197.243 29.7859C203.745 29.7859 208.094 24.7472 208.094 14.9134C208.094 5.07973 203.745 0.000976562 197.243 0.000976562ZM195.05 22.5129C191.556 22.5129 189.443 20.0748 189.443 14.9134C189.443 9.79341 191.597 7.27403 195.132 7.27403C198.464 7.27403 200.455 9.75342 200.455 14.9134C200.454 20.2773 198.544 22.5129 195.049 22.5129H195.05Z"
            fill="#8654CC"
          />
          <path
            d="M138.201 0.693359L132.805 18.12L127.554 0.693359H118.899L114.389 29.138H121.58L124.324 11.443L129.628 29.138H135.803L141.294 11.1256L143.93 29.138H151.244L146.734 0.693359H138.201Z"
            fill="#8654CC"
          />
          <path
            d="M250.069 22.797C246.275 22.797 243.851 20.3227 243.629 16.2954H263.072C263.072 16.2954 263.194 14.8325 263.194 13.3283C263.194 4.79495 258.115 0 250.15 0C241.738 0 235.887 6.13527 235.887 14.8725C235.887 23.7309 241.657 29.8262 250.069 29.8262C257.871 29.8262 263.112 25.3112 263.275 18.6922H255.473C255.027 21.2941 253.116 22.797 250.069 22.797ZM250.15 6.58419C253.293 6.58419 255.167 7.93999 255.686 10.8103H244.047C244.937 8.12317 247.136 6.58419 250.15 6.58419Z"
            fill="#8654CC"
          />
        </SvgIcon>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            sx={{ mb: 3 }}
            required
            fullWidth
            id="email"
            label="Рабочая почта"
            name="email"
            autoComplete="email"
            autoFocus
          />

          <TextField
            sx={{ mb: 3, display: isPasswordChange ? "none" : null }}
            disabled={isPasswordChange}
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Button
            color={
              isPasswordChange
                ? result.isSuccess
                  ? "success"
                  : result.isError
                  ? "error"
                  : "primary"
                : "primary"
            }
            disabled={result.isLoading}
            type={"submit"}
            fullWidth
            variant="contained"
            sx={{ mb: 3 }}
          >
            {isPasswordChange
              ? result.isSuccess
                ? "Письмо отправлено в телеграм"
                : result.isError
                ? "Почта не найдена"
                : "Отправить новый пароль"
              : "Войти"}
          </Button>
          <Link
            onClick={() => setIsPasswordChange(!isPasswordChange)}
            sx={{ cursor: "pointer" }}
            variant="body2"
            color="inherit"
          >
            {isPasswordChange ? "< Вход" : "Забыл пароль?"}
          </Link>
        </Box>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message="Неверный логин или пароль"
      />
    </Container>
  );
};

export default Auth;
