import { withProviders } from "./providers";
import { Routing } from "pages";
import { Header } from "widgets";
import { useAppSelector } from "shared/libs/redux/useAppSelector";
import { IUsersState } from "entities/user/model";

const App = () => {
  const user = useAppSelector((state: { user: IUsersState }) => state.user);

  return (
    <div className="app">
      {user.token && <Header />}
      <Routing />
    </div>
  );
};

export default withProviders(App);
