import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useAppSelector } from "shared/libs/redux";
import { ruRU } from "@mui/x-data-grid";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    mobile: true;
  }
}

export const withTheme = (Component: React.ComponentType) => () => {
  const state = useAppSelector((state) => state.theme);
  const theme = createTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          mobile: 400,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
      palette: {
        mode: state.mode,
        ...(state.mode === "light"
          ? {
              primary: {
                main: "rgba(105, 51, 176, 1)",
              },
              background: {
                default: "#f2f2f2",
                paper: "#ffffff",
              },
            }
          : {
              background: {
                paper: "#272626",
              },
              primary: {
                main: "#875bbf",
              },
            }),
        // secondary: {
        //   main: "rgba(221, 48, 48, 1)",
        //   light: "rgba(255, 159, 177, 1)",
        // },
      },
    },
    ruRU,
  );
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Component />
    </ThemeProvider>
  );
};
