import { memo, MutableRefObject, useState } from "react";
import Slider from "@mui/material/Slider";
import { Theme } from "@mui/system/createTheme/createTheme";
import dayjs from "dayjs";
import { ITimesheetRecord } from "entities/record/model";

type TimeTrackSliderProps = {
  record: ITimesheetRecord;
  updatePost: (record: ITimesheetRecord) => void;
  hours: MutableRefObject<HTMLInputElement | undefined>;
};
export const TimeTrackSlider = memo(
  ({ record, updatePost, hours }: TimeTrackSliderProps) => {
    const prepareDates: () => number[] = () => {
      function roundToMultipleOf15(number: number) {
        return Math.round(number / 15) * 15;
      }
      const startDateString = dayjs.utc(record.startDate).format("HH:mm");
      const endDateString = dayjs.utc(record.endDate).format("HH:mm");
      return [
        roundToMultipleOf15(
          startDateString.split(":").map(Number)[0] * 60 +
            startDateString.split(":").map(Number)[1]
        ),
        roundToMultipleOf15(
          endDateString.split(":").map(Number)[0] * 60 +
            endDateString.split(":").map(Number)[1]
        ),
      ];
    };
    const [minutes, setMinutes] = useState(prepareDates());
    const convertDates = (_: any, value: number[], activeThumb: number) => {
      const startMinutes = value[0];
      const endMinutes = value[1];
      const minDistance = 15;
      if (endMinutes - startMinutes < minDistance) {
        if (activeThumb === 0) {
          const clamped = Math.min(startMinutes, 1440 - minDistance);
          setMinutes([clamped, clamped + minDistance]);
        } else {
          const clamped = Math.max(endMinutes, minDistance);
          setMinutes([clamped - minDistance, clamped]);
        }
      } else {
        setMinutes([startMinutes, endMinutes]);
      }
    };
    const debounceRender = (value: number[]) => {
      if (hours && hours.current) {
        hours.current.value = `${+(
          Math.round(((value[1] - value[0]) / 60) * 4) / 4
        ).toFixed(2)}`;
      }
    };
    const updateOnCommit = (value: number[]) => {
      const startMinutes = value[0];
      const endMinutes = value[1];

      const startHours = `${String(Math.floor(startMinutes / 60)).padStart(
        2,
        "0"
      )}:${String(startMinutes % 60).padStart(2, "0")}`;

      const endHours = `${String(Math.floor(endMinutes / 60)).padStart(
        2,
        "0"
      )}:${String(endMinutes % 60).padStart(2, "0")}`;

      !record.isConfirmed &&
        dayjs.utc(record.startDate).isSame(dayjs.utc(record.endDate), "day") &&
        updatePost({
          ...record,
          startDate: dayjs.utc(
            dayjs.utc(record.startDate).format(`YYYY-MM-DD ${startHours}`)
          ),
          endDate: dayjs.utc(
            dayjs
              .utc(record.startDate)
              .format(`YYYY-MM-DD ${endHours === "24:00" ? "23:59" : endHours}`)
          ),
        });
    };
    function convertMinutesToTime(minutes: number) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;

      // Formatting the time as HH:mm
      const formattedTime = `${hours.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}`;

      return formattedTime;
    }

    return (
      <Slider
        disabled={!!record.isConfirmed}
        slotProps={{
          thumb: {
            ...{
              sx: {
                "&.Mui-focusVisible": {
                  boxShadow: (theme: Theme) =>
                    theme.palette.mode === "dark"
                      ? "0 0 0 4px rgba(144,202,249,0.36)"
                      : "0 0 0 4px rgba(28, 31, 34, 0.5)",
                },
                borderRadius: "2px",
                color: "primary",
                height: "16px",
                width: "4px",
                "&.Mui-active": {
                  boxShadow: (theme: Theme) =>
                    theme.palette.mode === "dark"
                      ? "0 0 0 6px rgba(144, 202, 249, 0.36)"
                      : "0 0 0 6px rgba(28, 31, 34, 0.5)",
                },
                "&:hover": {},
              },
            },
          },
        }}
        disableSwap
        sx={{
          "&.Mui-disabled": {
            color: (theme) =>
              theme.palette.mode === "dark" ? "#16615D" : "#2e7d328d",
          },
          "& .Mui-disabled": {
            color: (theme) =>
              theme.palette.mode === "dark" ? "#103a38" : "#2e7d32",
          },
          p: "16px 0",
          mb: 0,
          borderRadius: "4px",
          color: (theme) =>
            theme.palette.mode === "dark" ? "#875bbf" : "rgba(28, 31, 34, 0.5)",
          ".MuiSlider-rail": {
            height: "34px",
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#121212" : "background.default",
          },
          ".MuiSlider-track": { height: "30px", border: 0 },
          ".MuiSlider-mark": {
            height: "16px",
            "&:nth-of-type(5n+3)": { height: "28px" },
          },
          ".MuiSlider-markLabel": {
            display: { md: "none", lg: "block" },
            fontSize: "0.7rem",
            color: "text.disabled",
          },
          ".MuiSlider-markLabelActive": {
            color: "text.primary",
          },
        }}
        marks={(() => {
          const timeList = [];
          for (let i = 0; i <= 1440; i += 15) {
            const entry: { value: number; label?: string | number } = {
              value: i,
            };
            if (i === 0) {
              entry.label = "00";
            } else if (i % 60 === 0) {
              const hours =
                i / 60 < 10 ? "0" + Math.floor(i / 60) : Math.floor(i / 60);
              entry.label = hours;
            }
            timeList.push(entry);
          }
          return timeList;
        })()}
        value={minutes}
        step={15}
        max={1440}
        onChangeCommitted={(_, value) => updateOnCommit(value as number[])}
        onChange={(_, value, active) => {
          convertDates(_, value as number[], active);
          debounceRender(value as number[]);
        }}
        valueLabelDisplay="auto"
        getAriaLabel={() => "Dates"}
        valueLabelFormat={(e) => convertMinutesToTime(e)}
      />
    );
  }
);
