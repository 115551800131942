import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ThemeSwitch } from "entities/theme/ui";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "shared/libs/redux";
import { removeActiveUser } from "entities/user/model";

const pages = process.env.REACT_APP_IS_DEV
  ? [
      {
        name: "Планер",
        link: "schedule",
        access: ["SUPERVISOR", "USER", "TEAMLEAD", "MENTOR", "S4S"],
      },
      {
        name: "Таймтрек",
        link: "timesheet",
        access: ["SUPERVISOR", "USER", "TEAMLEAD", "MENTOR", "S4S"],
      },
      // {
      //   name: "Тренинги",
      //   link: "trainings",
      //   access: ["SUPERVISOR", "TEAMLEAD", "USER", "MENTOR", "S4S"],
      // },
      {
        name: "Моя Команда",
        link: "teams",
        access: ["SUPERVISOR", "TEAMLEAD", "MENTOR", "S4S"],
      },
      {
        name: "Управление УЗ",
        link: "employees",
        access: ["SUPERVISOR", "S4S"],
      },
      {
        name: "Отчётность",
        link: "accounting",
        access: ["SUPERVISOR", "S4S"],
      },
    ]
  : [
      {
        name: "Планер",
        link: "schedule",
        access: ["SUPERVISOR", "USER", "TEAMLEAD", "MENTOR", "S4S"],
      },
      {
        name: "Таймтрек",
        link: "timesheet",
        access: ["SUPERVISOR", "USER", "TEAMLEAD", "MENTOR", "S4S"],
      },
      // {
      //   name: "Тренинги",
      //   link: "trainings",
      //   access: ["SUPERVISOR", "TEAMLEAD", "USER", "MENTOR", "S4S"],
      // },
      {
        name: "Моя Команда",
        link: "teams",
        access: ["SUPERVISOR", "TEAMLEAD", "MENTOR", "S4S"],
      },
      {
        name: "Управление УЗ",
        link: "employees",
        access: ["SUPERVISOR", "S4S"],
      },
      {
        name: "Отчётность",
        link: "accounting",
        access: ["SUPERVISOR", "S4S"],
      },
    ];

export const Header = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  if (!user.token) {
    return null;
  }

  if (!user.token) {
    return null;
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      sx={{
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "text" : "rgba(255, 255, 255, 1)",
        boxShadow: "none",
      }}
      position="static"
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ height: 50 }} variant="dense" disableGutters>
          <SvgIcon
            inheritViewBox
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 2,
              width: 100,
              height: 35,
              fill: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 1)"
                  : "#3D3D3D",
            }}
          >
            <svg
              width="90"
              height="48"
              viewBox="0 0 90 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M35.0293 21.7077H39.0729V10.7971H44.4126V7.05322H35.0293V21.7077Z" />
              <path d="M26.6763 18.4406C26.2344 18.4611 25.7931 18.3892 25.3806 18.2294C24.9681 18.0697 24.5935 17.8257 24.2806 17.5129C23.9678 17.2001 23.7238 16.8256 23.564 16.4131C23.4042 16.0007 23.3323 15.5594 23.3528 15.1176H33.3233C33.3233 15.1176 33.3897 14.3644 33.3897 13.589C33.3897 9.15831 30.7642 6.72144 26.6652 6.72144C22.3336 6.72144 19.3203 9.87829 19.3203 14.3865C19.3203 18.8947 22.2893 22.1402 26.6209 22.1402C30.6423 22.1402 33.3454 19.8141 33.423 16.4025H29.468C29.349 17.0334 28.9941 17.5952 28.4755 17.9738C27.9569 18.3524 27.3136 18.5193 26.6763 18.4406ZM26.6763 10.0888C27.0095 10.0423 27.3486 10.0631 27.6735 10.15C27.9985 10.2369 28.3027 10.3881 28.5682 10.5947C28.8337 10.8013 29.055 11.059 29.2191 11.3526C29.3831 11.6462 29.4866 11.9698 29.5234 12.3041H23.5744C23.7746 11.6357 24.1936 11.0539 24.7642 10.6521C25.3347 10.2504 26.0237 10.052 26.7206 10.0888H26.6763Z" />
              <path d="M12.2747 7.05322L9.49408 16.0364L6.79098 7.05322H2.32644L0 21.7077H3.71122L5.11816 12.5916L7.85449 21.7077H11.034L13.87 12.4254L15.2215 21.7077H18.9882L16.6728 7.05322H12.2747Z" />
              <path d="M51.8239 6.69897C47.5144 6.69897 45.0661 8.81464 45.1769 12.4146H49.3645C49.3645 10.886 50.1621 10.1992 51.835 10.1992C53.0314 10.1992 53.7847 10.6977 53.7847 11.4509C53.7847 11.9383 53.5964 12.1266 52.8652 12.337L49.5417 13.2564C46.3069 14.1314 44.8667 15.4717 44.8667 17.6871C44.8667 20.3122 46.7722 22.0624 49.575 22.0624C50.4697 22.152 51.3697 21.9558 52.146 21.5021C52.9222 21.0483 53.5347 20.3604 53.8955 19.5369V21.7522H57.7618V11.9715C57.7064 8.43803 55.4686 6.69897 51.8239 6.69897ZM50.3837 18.6507C49.3534 18.6507 48.8549 18.1523 48.8549 17.4212C48.8549 16.6902 49.2094 16.3136 50.3172 15.9702L53.818 14.9843V15.2502C53.8288 15.7025 53.7471 16.1523 53.5779 16.572C53.4087 16.9916 53.1556 17.3723 52.834 17.6907C52.5125 18.0091 52.1293 18.2585 51.708 18.4236C51.2866 18.5886 50.836 18.666 50.3837 18.6507Z" />
              <path d="M77.5811 25.6182V29.3621H81.7687V40.2726H85.8122V29.3621H89.9998V25.6182H77.5811Z" />
              <path d="M63.0245 25.6182H58.571L55.4248 31.0568H53.8184V25.6182H49.7749V40.2726H53.8184V34.6568H55.4026L58.7815 40.2726H63.3457L58.8812 32.7959L63.0245 25.6182Z" />
              <path d="M26.9313 25.2639C22.6218 25.2639 20.1624 27.3796 20.2843 30.9795H24.4719C24.4719 29.4509 25.2695 28.7642 26.9423 28.7642C28.1388 28.7642 28.8921 29.2737 28.8921 30.0269C28.8921 30.5032 28.7038 30.6915 27.9615 30.902L24.6381 31.8213C21.4143 32.7075 19.9741 34.0367 19.9741 36.252C19.9741 38.8661 21.8796 40.6273 24.6824 40.6273C25.578 40.7242 26.481 40.5298 27.2573 40.0728C28.0337 39.6159 28.6419 38.9208 28.9918 38.0907V40.3061H32.8692V30.4921C32.8138 27.003 30.576 25.2639 26.9313 25.2639ZM25.4689 37.2157C24.4608 37.2157 23.9512 36.7172 23.9512 35.9751C23.9512 35.233 24.3168 34.8674 25.4246 34.5573L28.9143 33.5714V33.8151C28.9252 34.2684 28.8431 34.7192 28.6733 35.1396C28.5034 35.56 28.2493 35.9413 27.9265 36.2598C27.6038 36.5784 27.2193 36.8275 26.7966 36.9919C26.3739 37.1563 25.9221 37.2324 25.4689 37.2157Z" />
              <path d="M42.6953 25.2641C41.8505 25.2112 41.0109 25.4299 40.2992 25.8883C39.5876 26.3467 39.0414 27.0207 38.7404 27.8118V25.5964H34.6968V44.2717H38.7404V38.1131C39.052 38.8928 39.6025 39.5539 40.313 40.0016C41.0234 40.4493 41.8574 40.6606 42.6953 40.6054C46.0188 40.6054 48.2345 38.0134 48.2345 32.9403C48.2345 27.8671 46.052 25.2641 42.6953 25.2641ZM41.5875 36.8614C39.7374 36.8614 38.6296 35.5987 38.6296 32.9403C38.6296 30.2819 39.7374 29.008 41.5654 29.008C43.3933 29.008 44.3017 30.2819 44.3017 32.9403C44.3017 35.5987 43.3711 36.8614 41.5654 36.8614H41.5875Z" />
              <path d="M12.2747 25.6182L9.49408 34.5903L6.79098 25.6182H2.32644L0 40.2726H3.71122L5.11816 31.1565L7.85449 40.2726H11.034L13.87 30.9904L15.2215 40.2726H18.9882L16.6728 25.6182H12.2747Z" />
              <path d="M69.9146 37.0055C69.4731 37.0243 69.0325 36.9513 68.6208 36.7909C68.2091 36.6306 67.8351 36.3865 67.5227 36.0741C67.2102 35.7617 66.9661 35.3878 66.8057 34.9761C66.6454 34.5644 66.5723 34.1239 66.5911 33.6825H76.5615C76.5615 33.6825 76.628 32.9293 76.628 32.1539C76.628 27.7233 74.0135 25.2864 69.9035 25.2864C65.5719 25.2864 62.5586 28.4432 62.5586 32.9404C62.5586 37.4375 65.5276 40.6941 69.8592 40.6941C73.8806 40.6941 76.5837 38.368 76.6723 34.9563H72.7063C72.5897 35.5893 72.2358 36.1538 71.717 36.5346C71.1981 36.9155 70.5534 37.0839 69.9146 37.0055ZM69.9146 28.6537C70.2481 28.6087 70.5873 28.6306 70.9123 28.718C71.2374 28.8054 71.5417 28.9566 71.8078 29.1628C72.0738 29.369 72.2961 29.626 72.4618 29.9189C72.6274 30.2119 72.7332 30.5348 72.7728 30.869H66.8127C67.0111 30.1996 67.4297 29.6166 68.0007 29.2146C68.5716 28.8126 69.2616 28.6149 69.9589 28.6537H69.9146Z" />
              <path d="M35.0293 21.7077H39.0729V10.7971H44.4126V7.05322H35.0293V21.7077Z" />
              <path d="M26.6763 18.4406C26.2344 18.4611 25.7931 18.3892 25.3806 18.2294C24.9681 18.0697 24.5935 17.8257 24.2806 17.5129C23.9678 17.2001 23.7238 16.8256 23.564 16.4131C23.4042 16.0007 23.3323 15.5594 23.3528 15.1176H33.3233C33.3233 15.1176 33.3897 14.3644 33.3897 13.589C33.3897 9.15831 30.7642 6.72144 26.6652 6.72144C22.3336 6.72144 19.3203 9.87829 19.3203 14.3865C19.3203 18.8947 22.2893 22.1402 26.6209 22.1402C30.6423 22.1402 33.3454 19.8141 33.423 16.4025H29.468C29.349 17.0334 28.9941 17.5952 28.4755 17.9738C27.9569 18.3524 27.3136 18.5193 26.6763 18.4406ZM26.6763 10.0888C27.0095 10.0423 27.3486 10.0631 27.6735 10.15C27.9985 10.2369 28.3027 10.3881 28.5682 10.5947C28.8337 10.8013 29.055 11.059 29.2191 11.3526C29.3831 11.6462 29.4866 11.9698 29.5234 12.3041H23.5744C23.7746 11.6357 24.1936 11.0539 24.7642 10.6521C25.3347 10.2504 26.0237 10.052 26.7206 10.0888H26.6763Z" />
              <path d="M12.2747 7.05322L9.49408 16.0364L6.79098 7.05322H2.32644L0 21.7077H3.71122L5.11816 12.5916L7.85449 21.7077H11.034L13.87 12.4254L15.2215 21.7077H18.9882L16.6728 7.05322H12.2747Z" />
              <path d="M51.8239 6.69897C47.5144 6.69897 45.0661 8.81464 45.1769 12.4146H49.3645C49.3645 10.886 50.1621 10.1992 51.835 10.1992C53.0314 10.1992 53.7847 10.6977 53.7847 11.4509C53.7847 11.9383 53.5964 12.1266 52.8652 12.337L49.5417 13.2564C46.3069 14.1314 44.8667 15.4717 44.8667 17.6871C44.8667 20.3122 46.7722 22.0624 49.575 22.0624C50.4697 22.152 51.3697 21.9558 52.146 21.5021C52.9222 21.0483 53.5347 20.3604 53.8955 19.5369V21.7522H57.7618V11.9715C57.7064 8.43803 55.4686 6.69897 51.8239 6.69897ZM50.3837 18.6507C49.3534 18.6507 48.8549 18.1523 48.8549 17.4212C48.8549 16.6902 49.2094 16.3136 50.3172 15.9702L53.818 14.9843V15.2502C53.8288 15.7025 53.7471 16.1523 53.5779 16.572C53.4087 16.9916 53.1556 17.3723 52.834 17.6907C52.5125 18.0091 52.1293 18.2585 51.708 18.4236C51.2866 18.5886 50.836 18.666 50.3837 18.6507Z" />
              <path d="M77.5811 25.6182V29.3621H81.7687V40.2726H85.8122V29.3621H89.9998V25.6182H77.5811Z" />
              <path d="M63.0245 25.6182H58.571L55.4248 31.0568H53.8184V25.6182H49.7749V40.2726H53.8184V34.6568H55.4026L58.7815 40.2726H63.3457L58.8812 32.7959L63.0245 25.6182Z" />
              <path d="M26.9313 25.2639C22.6218 25.2639 20.1624 27.3796 20.2843 30.9795H24.4719C24.4719 29.4509 25.2695 28.7642 26.9423 28.7642C28.1388 28.7642 28.8921 29.2737 28.8921 30.0269C28.8921 30.5032 28.7038 30.6915 27.9615 30.902L24.6381 31.8213C21.4143 32.7075 19.9741 34.0367 19.9741 36.252C19.9741 38.8661 21.8796 40.6273 24.6824 40.6273C25.578 40.7242 26.481 40.5298 27.2573 40.0728C28.0337 39.6159 28.6419 38.9208 28.9918 38.0907V40.3061H32.8692V30.4921C32.8138 27.003 30.576 25.2639 26.9313 25.2639ZM25.4689 37.2157C24.4608 37.2157 23.9512 36.7172 23.9512 35.9751C23.9512 35.233 24.3168 34.8674 25.4246 34.5573L28.9143 33.5714V33.8151C28.9252 34.2684 28.8431 34.7192 28.6733 35.1396C28.5034 35.56 28.2493 35.9413 27.9265 36.2598C27.6038 36.5784 27.2193 36.8275 26.7966 36.9919C26.3739 37.1563 25.9221 37.2324 25.4689 37.2157Z" />
              <path d="M42.6953 25.2641C41.8505 25.2112 41.0109 25.4299 40.2992 25.8883C39.5876 26.3467 39.0414 27.0207 38.7404 27.8118V25.5964H34.6968V44.2717H38.7404V38.1131C39.052 38.8928 39.6025 39.5539 40.313 40.0016C41.0234 40.4493 41.8574 40.6606 42.6953 40.6054C46.0188 40.6054 48.2345 38.0134 48.2345 32.9403C48.2345 27.8671 46.052 25.2641 42.6953 25.2641ZM41.5875 36.8614C39.7374 36.8614 38.6296 35.5987 38.6296 32.9403C38.6296 30.2819 39.7374 29.008 41.5654 29.008C43.3933 29.008 44.3017 30.2819 44.3017 32.9403C44.3017 35.5987 43.3711 36.8614 41.5654 36.8614H41.5875Z" />
              <path d="M12.2747 25.6182L9.49408 34.5903L6.79098 25.6182H2.32644L0 40.2726H3.71122L5.11816 31.1565L7.85449 40.2726H11.034L13.87 30.9904L15.2215 40.2726H18.9882L16.6728 25.6182H12.2747Z" />
              <path d="M69.9146 37.0055C69.4731 37.0243 69.0325 36.9513 68.6208 36.7909C68.2091 36.6306 67.8351 36.3865 67.5227 36.0741C67.2102 35.7617 66.9661 35.3878 66.8057 34.9761C66.6454 34.5644 66.5723 34.1239 66.5911 33.6825H76.5615C76.5615 33.6825 76.628 32.9293 76.628 32.1539C76.628 27.7233 74.0135 25.2864 69.9035 25.2864C65.5719 25.2864 62.5586 28.4432 62.5586 32.9404C62.5586 37.4375 65.5276 40.6941 69.8592 40.6941C73.8806 40.6941 76.5837 38.368 76.6723 34.9563H72.7063C72.5897 35.5893 72.2358 36.1538 71.717 36.5346C71.1981 36.9155 70.5534 37.0839 69.9146 37.0055ZM69.9146 28.6537C70.2481 28.6087 70.5873 28.6306 70.9123 28.718C71.2374 28.8054 71.5417 28.9566 71.8078 29.1628C72.0738 29.369 72.2961 29.626 72.4618 29.9189C72.6274 30.2119 72.7332 30.5348 72.7728 30.869H66.8127C67.0111 30.1996 67.4297 29.6166 68.0007 29.2146C68.5716 28.8126 69.2616 28.6149 69.9589 28.6537H69.9146Z" />
            </svg>
          </SvgIcon>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map(
                (page) =>
                  user.role &&
                  page.access.includes(user.role) && (
                    <MenuItem
                      key={page.name}
                      onClick={() => {
                        handleCloseNavMenu();
                        navigate(`/${page.link}`);
                      }}
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ),
              )}
            </Menu>
          </Box>
          {/* 
          <SvgIcon
            inheritViewBox
            sx={{
              flexGrow: 1,
              display: { xs: "none", mobile: "flex", md: "none" },
              mr: 2,
              minWidth: 153,
            }}
          >
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M25.05 12.34C24.9 5.552 19.354.108 12.532.108 5.614.108 0 5.717 0 12.628h5.01c0 5.526 4.489 10.01 10.02 10.01 5.436 0 9.87-4.333 10.02-9.736v-.562Zm-10.02 5.293a5 5 0 0 1-5.01-5.005 5 5 0 0 1 5.01-5.005 5 5 0 0 1 5.01 5.005 5 5 0 0 1-5.01 5.005ZM96.371 4.77c-4.365 0-7.92 3.525-7.92 7.844 0 4.402 3.473 7.844 7.92 7.844 4.434 0 7.92-3.442 7.92-7.844-.014-4.32-3.555-7.843-7.92-7.843Zm0 12.41c-2.333 0-4.035-2.083-4.035-4.552 0-2.468 1.688-4.553 4.035-4.553 2.279 0 3.994 2.126 3.994 4.553 0 2.469-1.688 4.553-3.994 4.553ZM61.575 5.113l-.013 1.742s-.179-.22-.33-.384c-.74-.891-2.182-1.687-4.282-1.687-3.885 0-7.056 3.524-7.056 7.844 0 4.333 3.171 7.844 7.056 7.844 2.114 0 3.541-.795 4.282-1.687.151-.178.33-.384.33-.384l.014 1.742h3.843V5.113h-3.843Zm.234 7.515c0 2.564-1.579 4.553-3.967 4.553s-4.063-1.989-4.063-4.553 1.675-4.553 4.063-4.553c2.388-.013 3.967 1.975 3.967 4.553 0-.014 0-.014 0 0ZM133.446 5.113l-.014 1.742s-.179-.22-.33-.384c-.741-.891-2.182-1.687-4.282-1.687-3.885 0-7.055 3.524-7.055 7.844 0 4.333 3.17 7.844 7.055 7.844 2.114 0 3.541-.795 4.282-1.687.151-.178.33-.384.33-.384l.014 1.742h3.843V5.113h-3.843Zm.233 7.515c0 2.564-1.579 4.553-3.967 4.553s-4.063-1.989-4.063-4.553 1.675-4.553 4.063-4.553c2.388-.013 3.967 1.975 3.967 4.553 0-.014 0-.014 0 0ZM44.116 10.626h3.885c-.948-3.675-3.748-5.855-7.522-5.855-4.516 0-7.92 3.373-7.92 7.843s3.404 7.844 7.92 7.844c3.774 0 6.574-2.18 7.522-5.855h-3.899c-.7 1.577-1.949 2.564-3.623 2.564-2.375 0-4.036-1.975-4.036-4.553 0-2.591 1.661-4.552 4.036-4.552 1.674 0 2.937.987 3.637 2.564ZM72.159 5.113H68.85V20.13h3.857V10.9l4.612 5.897 4.584-5.897v9.23h3.857V5.112h-3.308l-5.16 6.596-5.134-6.596ZM120.818 5.113h-4.544l-5.477 6.596V5.113h-3.857V20.13h3.857v-6.486l6.109 6.486h4.927l-7.275-7.624 6.26-7.392ZM144.441 20.13h3.857V8.35h4.543V5.113h-12.93V8.35h4.53v11.78Z"
            />
          </SvgIcon> */}
          {/* <SvgIcon sx={{ flexGrow: 1 }} inheritViewBox>
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M25.05 12.34C24.9 5.552 19.354.108 12.532.108 5.614.108 0 5.717 0 12.628h5.01c0 5.526 4.489 10.01 10.02 10.01 5.436 0 9.87-4.333 10.02-9.736v-.562Zm-10.02 5.293a5 5 0 0 1-5.01-5.005 5 5 0 0 1 5.01-5.005 5 5 0 0 1 5.01 5.005 5 5 0 0 1-5.01 5.005ZM96.371"
            />
          </SvgIcon> */}
          <Box
            sx={{ gap: 1, flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            {pages.map(
              (page) =>
                user.role &&
                page.access.includes(user.role) && (
                  <Button
                    key={page.name}
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate(`/${page.link}`);
                    }}
                    sx={{
                      mt: "18px",
                      mb: "16px",
                      color: (theme) =>
                        theme.palette.mode === "dark" ? "white" : "black",
                      display: "block",
                    }}
                  >
                    {page.name}
                  </Button>
                ),
            )}
          </Box>

          <ThemeSwitch />
          <Box>
            <Box
              onClick={handleOpenUserMenu}
              display="flex"
              sx={{
                cursor: "pointer",
                px: 1,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
                flexGrow: 0,
                gap: 1,
              }}
            >
              <Avatar
                sx={{
                  height: 30,
                  width: 30,
                  display: { mobile: "none", sm: "flex" },
                }}
                variant="rounded"
                alt="R"
              />

              <Box
                sx={{
                  minWidth: 70,
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "white" : "black",
                }}
                display="block"
              >
                <Typography component="span" variant="caption">
                  Привет,
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: "-5px",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "white" : "black",
                  }}
                >
                  {user.fullName.split(" ")[1]}!
                </Typography>
              </Box>
              <Tooltip title="Open settings">
                <IconButton
                  sx={{
                    width: 24,
                    height: 24,
                    display: { mobile: "none", sm: "flex" },
                  }}
                  size="small"
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box
                component="li"
                display="flex"
                sx={{
                  p: "6px",
                  pt: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                  flexGrow: 0,
                  gap: 1,
                }}
              >
                <Avatar
                  sx={{
                    height: 30,
                    width: 30,
                    display: { mobile: "none", sm: "flex" },
                  }}
                  variant="rounded"
                  alt="R"
                />

                <Box sx={{ minWidth: 70 }} display="block">
                  <Typography variant="subtitle2">{user.fullName}</Typography>
                  <Typography
                    color={"text.disabled"}
                    component="span"
                    variant="caption"
                    sx={{}}
                  >
                    {user.contract} - {user.role}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <MenuItem
                key="logout"
                onClick={() => {
                  handleCloseUserMenu();
                  dispatch(removeActiveUser());
                }}
              >
                <Typography textAlign="center">Выйти</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
